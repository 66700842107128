var stationBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: '/api/stations/search?q=%QUERY',
        wildcard: '%QUERY'
    }
});

var searchField = $('#stations-search');
searchField.typeahead({
    minLength: 1,
    highlight: true
}, {
    name: 'stations-search',
    display: 'name',
    source: stationBloodhound
});

searchField.on('focus', function () {
        if (window.innerWidth <= 768) {
            document.body.scrollTop = $(this).offset().top - 120
        }
    }
).on('typeahead:selected', function (e, data) {
        console.log("submitting");
        var form = document.getElementById('station-search-form');
        if (form) {
            console.log(form);
            form.submit();
        }
    }
);
